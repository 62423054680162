<template>
  <a-card :bordered="false">
    <div>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="24">
            <a-col :md="6" :sm="24">
              <a-form-item label="仓库">
                <a-select
                  :not-found-content="warehouse_fetching ? undefined : null"
                  :default-active-first-option="false"
                  :filter-option="false"
                  v-model="queryParam.to_warehouse_id"
                  placeholder="选择仓库"
                  allow-clear
                  show-search
                  @search="this.warehouseSearch"
                  @change="handleWarehouseChange"
                >
                  <a-spin v-if="warehouse_fetching" slot="notFoundContent" size="small" />
                  <a-select-option v-for="d in commonWarehouse" :key="d.id" :value="d.id">
                    {{ d.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="库位">
                <a-select
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  placeholder="选择库位"
                  allow-clear
                  show-search
                  @search="handleCargoSpaceSearch"
                  v-model="queryParam.cargo_space_id"
                >
                  <a-select-option v-for="d in warehouseCargoSpace" :key="d.id" :value="d.id">
                    {{ d.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="货品条形码">
                <a-input
                  v-model="queryParam.barcode"
                  placeholder="请输入条形码"
                  @keyup.enter.native="$refs.table.refresh(true)"
                  allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="2" :sm="24">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </a-col>
            <a-col :md="2" :sm="24">
              <a-button type="primary" icon="sync" @click="syncInventoryCount">同步库存</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
      </s-table>
      <a-modal
        title="同步库存"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
      >
        <p>{{ ModalText }}</p>
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { wms_warehouse_goods_inventory_sync, wms_warehouse_inventory_list } from '@/api/c_wms_period_inventory'
import { warehouse_list } from '@/api/c_wms_warehouse'
import { cargo_space_list } from '@/api/c_wms_cargo_space'
import debounce from 'lodash/debounce'

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    this.warehouseSearch = debounce(this.handleWarehouseSearch, 800)
    return {
      visible: false,
      confirmLoading: false,
      loading: true,
      warehouse_fetching: false,
      commonWarehouse: [],
      warehouseCargoSpace: [],
      // 查询参数
      queryParam: {
        warehouse_id: undefined,
        cargo_space_id: undefined
      },
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '仓库',
          align: 'center',
          dataIndex: 'warehouse'
        },
        {
          title: '库位',
          align: 'center',
          dataIndex: 'cargo_space'
        },
        {
          title: '货品批次',
          align: 'center',
          dataIndex: 'goods_purchase_batch'
        },
        {
          title: '货品名称',
          align: 'center',
          dataIndex: 'goods_name'
        },
        {
          title: '货品条形码',
          align: 'center',
          dataIndex: 'goods_purchase_barcode'
        },
        {
          title: '数量',
          align: 'center',
          dataIndex: 'count'
        },
        {
          title: '冻结数量',
          align: 'center',
          dataIndex: 'freeze_count'
        },
        {
          title: '可用数量',
          align: 'center',
          dataIndex: 'available_count'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return wms_warehouse_inventory_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          }).finally(() => {
            this.loading = false
          })
      }
    }
  },
  created () {
    this.handleWarehouseSearch(undefined)
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleWarehouseChange (value) {
      this.queryParam.warehouse_id = value
      if (value === undefined) {
        this.handleWarehouseSearch(undefined)
      }
      this.handleCargoSpaceSearch(undefined)
    },
    handleWarehouseSearch (value) {
      this.commonWarehouse = []
      this.warehouse_fetching = true
      warehouse_list({ name: value }).then(({ data }) => {
        this.commonWarehouse = data.entries
      }).finally(() => {
        this.warehouse_fetching = false
      })
    },
    handleCargoSpaceSearch (value) {
      this.warehouseCargoSpace = []
      this.queryParam.cargo_space_id = undefined
      if (!this.queryParam.warehouse_id) {
        return
      }
        cargo_space_list({ warehouse_id: this.queryParam.warehouse_id, name: value }).then(res => {
        this.warehouseCargoSpace = res.data.entries
      })
    },
    syncInventoryCount () {
      console.log('同步库存数量')
      this.visible = true
      this.ModalText = '“库存数量”同步到“销售端”商品数量，确定同步吗？'
    },
    handleOk () {
      this.confirmLoading = true
      wms_warehouse_goods_inventory_sync().then(res => {
        console.log(res)
      }).finally(() => {
        this.visible = false
        this.confirmLoading = false
      })
    },
    handleCancel () {
      this.visible = false
    }
  }
}
</script>
